<template>
  <div class="pagination">
    <div class="prev" @click="prevClick">
      <img src="@/assets/images/arrow-right.png">
    </div>
    <span class="num">{{currentPage}}</span>
    <div class="next" @click="nextClick">
      <img src="@/assets/images/arrow-right.png">
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  props: {
    currentPage: {
      type: [String, Number],
      default: 1
    }
  },
  methods: {
    prevClick() {
      if(this.currentPage <= 1) return
      this.$emit('prev-click', this.currentPage - 1)
      this.$emit('current-change', {
        type: 'prev',
        page: this.currentPage - 1
      })
    },
    nextClick() {
      this.$emit('next-click', this.currentPage + 1)
      this.$emit('current-change', {
        type: 'next',
        page: this.currentPage + 1
      })
    },
  }
}
</script>

<style lang="scss" scoped>
// 分页
.pagination {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 14px;
  color: #65666F;
  .prev, .next {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    cursor: pointer;
    img {
      width: 15px;
      height: 15px;
    }
  }
  .prev {
    transform: rotate(180deg);
  }
  .num {
    width: 32px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
  }
}
</style>